<template>
   <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <h2>Page non trouvée</h2>
        <v-btn
          class="cnxButton bg-cep-blue"
          color="#000091"
          @click="goToHome"
        >
          Retour à l'accueil
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'error-404',
  methods: {
    goToHome() {
      this.$auth
        .logout({
            makeRequest: false,
            redirect: { name: 'Login' },
        });
      localStorage.removeItem('role');
      localStorage.removeItem('token');
      delete this.axios.defaults.headers.common['Authorization'];
      // this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss">
  @import '../assets/styles/buttons.scss';
  .cnxButton {
    margin-top: 20px;
  }
</style>
